import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';
import { loadScript } from './utilities/js/helper';
import { Consent } from './layout/consent/consent';

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1200,
        xxl: 1440,
        xxxl: 1920
    }
};

const eventScroller = new EventScroller();
const resizeHandler = new ResizeHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;
window.krpanoApi = false;

/**
 * define function for initialising modules
 */

const initContent = ($content) => {
    console.log('start app');

    loadLazyImages();

    const $textlinks = $content.querySelectorAll('.textlink');
    for (let i = 0; i < $textlinks.length; i++) {
        const $textlink = $textlinks[i];
        const origHtml = $textlink.innerHTML;
        const newHtml = '<span>' + origHtml + '</span>';
        $textlink.innerHTML = newHtml;
    }

    const $consent = $content.querySelector('.smp-consent__wrapper');
    $consent.API = new Consent($consent);

    // Load Google translate if Browserlanguage is not in 'de'
    if (document.documentElement.classList.contains('no-de')) {
        loadScript('//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'); // eslint-disable-line
    }
};

/**
 * starting point
 */

domready(function () {
    const initApplication = () => {
        initContent(document);

        eventScroller.init();
        resizeHandler.init();

        const $backToTop = document.querySelector('[data-backtotop="root"]');
        if ($backToTop) {
            import('./components/back-to-top/back-to-top').then(({ BackToTop }) => {
                new BackToTop($backToTop, { // eslint-disable-line
                    eventScroller: eventScroller
                });
            });
        }

        // Stop jumping page to hash if "hash" were set
        if (location.hash) {
            setTimeout(() => {
                window.scrollTo(0, window.scrollY);
            }, 1);
        }
        const locationHash = window.location.hash;
        if (locationHash !== '') {
            import('./areas/anchor/anchor').then(({ Anchor }) => {
                const locationAnchor = new Anchor(locationHash); // eslint-disable-line
            });
        }

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document,
                $resize: resizeHandler,
                $scroll: eventScroller
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        // Remove no Transitions for all Elements at Start
        setTimeout(() => {
            document.body.classList.remove('has--no-animation');
        }, 1000);
    };

    if (window.Modernizr.ie11) {
        loadScript('https://polyfill.io/v3/polyfill.min.js?features=HTMLPictureElement%2CCustomEvent%2CEvent%2CObject.assign%2CPromise%2CElement.prototype.closest,NodeList.prototype.forEach,Object.keys,requestIdleCallback', () => {
            initApplication();
        });
    } else {
        initApplication();
    }
});
