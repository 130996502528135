const loadLazyImages = () => {
    const images = document.querySelectorAll('[loading="lazy"]');

    for (let i = 0; i < images.length; i++) {
        const image = images[i];

        if (image !== null) {
            image.classList.add('lazyload');
        }
    }
};

export { loadLazyImages };
