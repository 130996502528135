import './consent.scss';

class Consent {
    constructor (element, options) {
        this.$consentWrapper = document.querySelector('[data-consent="root"]');
        this.$consentOpen = document.querySelectorAll('.smp-unsubscribed');
        this.$consentClose = this.$consentWrapper.querySelector('[data-consent="close"]');
        this.$consentAccept = this.$consentWrapper.querySelector('[data-consent="accept"]');

        this.initialize();
    }

    initialize () {
        const self = this;
        this.conditionWait(20, 250, function () {
            return window.UC_UI !== undefined && typeof window.UC_UI === 'object';
        }, function () {
            self.checkLinks();
            self.checkSubscription();
            self.setEvents();
        });
    }

    conditionWait (counter, time, fctConditon, fctAction) {
        const self = this;
        counter--;
        if (fctConditon()) {
            fctAction();
        } else {
            if (counter >= 0) {
                setTimeout(function () {
                    self.conditionWait(counter, time, fctConditon, fctAction);
                }, time);
            }
        }
    }

    checkSubscription () {
        const cookieSet = UC_UI.getServicesBaseInfo().find(service => service.id === "S1pcEj_jZX").consent.status;// eslint-disable-line

        if (cookieSet) {
            this.$consentOpen.forEach((button) => {
                button.classList.remove('smp-unsubscribed');
            });
            this.$consentOpen = document.querySelectorAll('.smp-unsubscribed');
        }
    }

    checkLinks () {
        const cookieSet = UC_UI.getServicesBaseInfo().find(service => service.id === "S1pcEj_jZX").consent.status;// eslint-disable-line

        if (!cookieSet) {
            const regex = new RegExp('location=([^&]+)&'); // eslint-disable-line
            const allLinks = document.querySelectorAll('[href*="location="]');
            allLinks.forEach((link) => {
                if (regex.test(link.getAttribute('href'))) {
                    link.addEventListener('click', function (event) {
                        event.preventDefault();
                    });
                    const element = document.createElement('div');
                    link.appendChild(element);
                    element.classList.add('smp-unsubscribed');
                    element.classList.add('smp-link');
                    element.setAttribute('smp-link-href', link.getAttribute('href'));
                }
            });
            this.$consentOpen = document.querySelectorAll('.smp-unsubscribed');
        }
    }

    setEvents () {
        this.$consentClose.addEventListener('click', () => {
            this.$consentWrapper.classList.remove('is--visible');
            document.body.classList.remove('has--darkoverlay');
        });

        this.$consentAccept.addEventListener('click', () => {
            UC_UI.acceptService('S1pcEj_jZX').then(() => { // eslint-disable-line
                if (this.$consentWrapper.hasAttribute('smp-link-href')) {
                    window.location.href = this.$consentWrapper.getAttribute('smp-link-href');
                } else {
                    location.reload();
                }
            });
        });

        this.$consentOpen.forEach((button) => {
            button.addEventListener('click', (e) => {
                this.$consentWrapper.classList.add('is--visible');
                if (button.classList.contains('smp-link')) {
                    const link = button.getAttribute('smp-link-href');
                    this.$consentWrapper.setAttribute('smp-link-href', link);
                }
                document.body.classList.add('has--darkoverlay');
            });
        });
    }
}

export { Consent };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $consent = $context.querySelector('.smp-consent__wrapper');
        $consent.API = new Consent($consent);
    }
});
